<script setup>
	import {useMainStore} from "@/stores/mainStore";
	import {bori} from "@/assets/wafle/bori";
	import {onBeforeUnmount, onMounted, onUnmounted, watch,ref} from "vue";
	import compNav from "@/components/ranking_board/nav.vue";
	import compPrimaryRanking from "@/components/ranking_board/primary_ranking.vue";
	import compRanking from "@/components/ranking_board/ranking.vue";
	import searchedData from "@/assets/json/datas_search.json";
	const mainStore = useMainStore();
	mainStore.searchedData = false;


	let WW;
	let WH;
	let SB;
	let searchValue;
	let posinput;

	let resizeHandler = () =>{
		WW = window.innerWidth;
		WH = window.innerHeight;
		mainStore.isMobile = bori.isMobile(window.innerWidth);
		posinput = bori.getPosY(document.querySelector('.input-box'));
		scrollHandler();
	}
	let scrollHandler = () =>{
		SB = window.scrollY+WH;
		mainStore.hideHeader = window.scrollY > posinput - document.querySelector('header').clientHeight + document.querySelector('.input-box').clientHeight;
	}
	let showSearchedData = () => {
		if( mainStore.searchVal !== undefined ){
			fetch('https://runjeju-record.com/data/search', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					search: mainStore.searchVal,
				})
			})
				.then(response => {
					if (!response.ok) {
						throw new Error('Network response was not ok ' + response.statusText);
					}
					return response.json();
				})
				.then(data => {
					mainStore.isSearchOpened = true;
					mainStore.searchedData = data;
					setTimeout(()=>{
						document.querySelector('#dim').classList.add('onShow');
						document.querySelector(".modal-search").classList.add("onShow");
					},100);
				})
				.catch(error => {
					console.error('There has been a problem with your fetch operation:', error);
				});
		}
	}

	let keyDownHandler = () => {
		if( event.keyCode === 13 ){
			showSearchedData();
		}
	}

	onMounted(() => {
		window.addEventListener('resize',resizeHandler);
		window.addEventListener('scroll',scrollHandler);

		window.dispatchEvent(new Event('resize'));
		window.dispatchEvent(new Event('scroll'));

		window.addEventListener('keydown',keyDownHandler);
		bori.gaTagHandler('rankingMedia PV','rankingMedia PV');
	});
	onBeforeUnmount(()=>{
	})
	onUnmounted(() => {
		window.removeEventListener('resize',resizeHandler);
		window.removeEventListener('scroll',scrollHandler);
	});
</script>
<template>
	<div class="inner-container">
		<div class="contents">
			<div class="main-info">
				<div class="txt-box">
					<h1>
						RANKING<br class="m">
						BOARD
					</h1>
					<span>
						SEPTEMBER 5-8<br>NIKE RUN JEJU 2024<br class="m"> PRELIMINARY
					</span>
				</div>
				<div class="input-box">
					<input type="text" placeholder="우리 팀의 기록을 확인해 주세요." v-model="mainStore.searchVal">
					<span @click="showSearchedData"><img src="@/assets/image/ranking/btn_search.png"></span>
				</div>
				<comp-nav />
<!--				<comp-primary-ranking v-show="!mainStore.isMobile" />-->
			</div>
			<div class="detail-info">
				<comp-ranking />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/assets/scss/ranking/ranking';
</style>