<script setup>
	import {useMainStore} from "@/stores/mainStore";
	const mainStore = useMainStore();
	mainStore.isSearchOpened = false;

	import Contents from "@/components/ranking_board/contents.vue";
	import compHeader from "@/components/ranking_board/header.vue";
	import Search from '@/components/ranking_board/search.vue'
	import compFooter from "@/components/footer.vue";

</script>
<style>
	#dim{ opacity:0; transition:opacity 0.6s linear; background-color:#000; position:fixed; width:100%; height:100%; left:0; top:0; pointer-events:none; }
	#dim.onShow{ opacity:0.9; }
</style>
<template>
	<div class="container main" :class="{'onShow':mainStore.isInited}">
		<comp-header />
		<Contents />
		<div id="dim"></div>
		<Search />
		<comp-footer class="main"/>
	</div>
</template>