import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
// import VueCookies from 'vue-cookies'
// import VueYtframe from "vue3-ytframe"

const app = createApp(App);
import { register } from 'swiper/element/bundle';
import axios from 'axios';
register();
app.provide('$axios', axios);
app.use(createPinia())
// app.use(VueCookies)
app.use(router)
// app.use(VueYtframe)
app.mount('#app')