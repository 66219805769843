<script setup>
	import {useMainStore} from "@/stores/mainStore";
	import {onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
	import {bori} from "@/assets/wafle/bori";

	const mainStore = useMainStore();
	let selectedGender = ref(0);
	selectedGender.value = 'men';

	let WW;
	let WH;
	let SB;

	let resizeHandler = () => {
		WW = window.innerWidth;
		WH = window.innerHeight;
		mainStore.isMobile = bori.isMobile(window.innerWidth);
	}
	let scrollHandler = () => {
		resizeHandler();
		SB = window.scrollY + WH;
	}
	let btnCntroller = (idx) => {
		selectedGender.value = idx;
		mainStore.selectedGender = idx;
	}

	onMounted(() => {
		window.addEventListener('resize', resizeHandler);
		window.addEventListener('scroll', scrollHandler);

		window.dispatchEvent(new Event('resize'));
		window.dispatchEvent(new Event('scroll'));

		// bori.setHeightWithOutSoftBar();
	});
	onBeforeUnmount(() => {
	})
	onUnmounted(() => {
		window.removeEventListener('resize', resizeHandler);
		window.removeEventListener('scroll', scrollHandler);
	});
</script>
<template>
	<nav>
		<ul>
			<li :class="{ onShow: selectedGender === 'men' }" @click="btnCntroller('men')">남</li>
			<li :class="{ onShow: selectedGender === 'women' }" @click="btnCntroller('women')">여</li>
		</ul>
	</nav>
</template>

<style lang="scss" scoped>
	@import '@/assets/scss/ranking/nav';
</style>