<script setup>
	import {useMainStore} from "@/stores/mainStore";
	import {onBeforeUnmount, onMounted, onUnmounted, watch,ref} from "vue";
	import {bori} from "@/assets/wafle/bori";
	const mainStore = useMainStore();
	let searchHandler = () =>{
		mainStore.isSearchOpened = true;
		setTimeout(()=>{
			document.querySelector('#dim').classList.add('onShow');
			document.querySelector(".modal-search").classList.add("onShow");
		},100);
	}
	let currentRouter = window.router;
	let logout = () => {
		sessionStorage.setItem('username', '');
		sessionStorage.setItem('isChecked', '');
		location.reload();
	}

</script>
<template>
	<header v-if="mainStore.isLogin === true || currentRouter !== 'photoBoardView' || currentRouter !== 'final_photoBoardView'">
		<div class="logo"><img src="@/assets/image/ranking/logo.png"></div>
		<div class="search" @click="searchHandler" v-if="currentRouter !== 'photoBoardView' && currentRouter !=='final_photoBoardView'" :class="{'onShow':mainStore.hideHeader}"><span>우리 팀의 기록을 확인해 주세요</span><img src="@/assets/image/ranking/btn_search.png"></div>
		<div v-if="mainStore.isLogin" class="btn-logout" @click="logout">로그아웃</div>
	</header>
</template>

<style lang="scss" scoped>
	@import '@/assets/scss/header';
</style>