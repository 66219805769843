<script setup>
	import {useMainStore} from "@/stores/mainStore";
	import {onBeforeUnmount, onMounted, onUnmounted, watch, ref, nextTick} from "vue";
	import {bori} from '@/assets/wafle/bori';
	const mainStore = useMainStore();
	import searchedData from '@/assets/json/datas_search.json';

	let searchValue;
	let showSearchResult = ref(false);
	let searchDatas = ref();
	let menData = ref([]);
	let womenData = ref([]);
	let showSearchedData = () => {
		bori.gaTagHandler('전체 랭킹사이트 검색버튼 클릭','전체 랭킹사이트 검색버튼 클릭');
		fetch('https://runjeju-record.com/data/search', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				search: mainStore.searchVal,
			})
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok ' + response.statusText);
				}
				return response.json();
			})
			.then(data => {
				menData.value = [];
				womenData.value = [];

				if( data.status !== 'fail' ){
					data.data.forEach((item, index) => {
						if( item.gender === 'M'){
							menData.value.push(item)
						}else{
							womenData.value.push(item)
						}
					});


					searchDatas.value = data.data;
					mainStore.searchedData = data.data;

					setTimeout(()=>{
						showSearchResult.value = true;
					},600);
				}else{
					mainStore.searchedData = false;
					showSearchResult.value = false;
					setTimeout(()=>{
						showSearchResult.value = false;
					},600);
				}
			})
			.catch(error => {
				console.error('There has been a problem with your fetch operation:', error);
			});
	}
	let closeModal = () => {
		document.querySelector(".modal-search").classList.remove("onShow");
		document.querySelector('#dim').classList.remove('onShow');
		setTimeout(()=>{
			mainStore.isSearchOpened = false;
			showSearchResult.value = false;
			mainStore.searchedData = false;
		},600);
	}

	let keyDownHandler = () => {
		if( event.keyCode === 13 ){
			showSearchedData();
		}
	}

	onMounted(() => {
		const DELAY = 100;
		watch(() => searchDatas.value, (newValue) => {
			setTimeout(()=>{
				document.querySelectorAll('.search li').forEach((item, index) => {
					item.classList.add('onShow');
				})
			},DELAY);
		});
		watch(() => mainStore.isSearchOpened, (newValue) => {
			if( mainStore.searchedData !== false ) showSearchedData();
		})
		window.addEventListener('keydown',keyDownHandler);
	});
	onUnmounted(() => {
		window.removeEventListener('keydown',keyDownHandler)
	})
</script>
<template>
	<div class="modal-search" v-show="mainStore.isSearchOpened">
		<div class="inner-search">
			<button @click="closeModal"><img src="@/assets/image/ranking/btn_close.png"></button>
			<div class="txt-box">
				<input type="text" placeholder="팀명을 입력해주세요." v-model="mainStore.searchVal">
				<span @click="showSearchedData"><img src="@/assets/image/ranking/btn_search.png"></span>
			</div>
			<div class="data-box">
				<div class="men">
					<span class="gender">남성 팀</span>
					<ul class="has-data" v-if="menData.length !== 0">
						<li v-for="data in menData" :key="data.rank" :class="{'onShow':showSearchResult}">
							<div class="mask">
								<div id="rank"><span>{{data.rank}}</span></div>
								<div id="number"><span>{{data.num}}</span></div>
								<div id="teamName"><span>{{data.name}}</span></div>
								<div id="gender"><span>
								<em v-if="data.gender==='M'">남</em>
								<em v-else-if="data.gender==='F'">여</em>
								<em v-else>-</em>
							</span></div>
								<div id="score">
                  <span v-if="data.is_disqualified === 'Y' || data.is_participate === '미참가'">
                    00:00.00
                    </span>
                  <span v-else>
                    {{data.record_nettime}}
                  </span>
                </div>
							</div>
						</li>
					</ul>
					<ul class="no-data" v-else>
						<li :class="{'onShow':!showSearchResult.value}">
							<div class="mask">
								<div>검색 결과가 없습니다.</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="women">
					<span class="gender">여성 팀</span>
					<ul class="has-data" v-if="womenData.length !== 0">
						<li v-for="data in womenData" :key="data.rank" :class="{'onShow':showSearchResult}">
							<div class="mask">
								<div id="rank"><span>{{data.rank}}</span></div>
								<div id="number"><span>{{data.num}}</span></div>
								<div id="teamName"><span>{{data.name}}</span></div>
								<div id="gender"><span>
								<em v-if="data.gender==='M'">남</em>
								<em v-else-if="data.gender==='F'">여</em>
								<em v-else>-</em>
							</span></div>
								<div id="score">
                  <span v-if="data.is_disqualified === 'Y' || data.is_participate === '미참가'">
                    00:00.00
                    </span>
                  <span v-else>
                    {{data.record_nettime}}
                  </span>
                </div>
							</div>
						</li>
					</ul>
					<ul class="no-data" v-else>
						<li :class="{'onShow':!showSearchResult.value}">
							<div class="mask">
								<div>검색 결과가 없습니다.</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/assets/scss/search';
</style>