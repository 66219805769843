<script setup>
	let currentRouter = window.router;
</script>
<style lang="scss" scoped>
	@import '@/assets/scss/footer';
</style>

<template>
  <footer>
    <div class="inner-footer">
			<img src="../assets/image/nike_logo_orange.png" v-if="currentRouter === 'photoBoardView'">
			<img src="../assets/image/nike_logo_whitepng.png" v-else>
			<div class="txt-box">
				<span>운영사무국</span><em>|</em>
				<span>이메일: <a href="mailto:nikerunjeju@diplatform.com">nikerunjeju@diplatform.com</a></span><em>|</em>
				<span>전화번호: 02-579-9148~9150 (3개 회선)</span><em>|</em>
				<span>운영시간: 평일 오전 10시 ~ 오후 6시</span>
			</div>
    </div>
  </footer>
</template>