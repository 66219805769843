let bori = (() =>{
    const _sys = (() =>{
        const prohibitIE = (gtm) =>{
            const agent = navigator.userAgent.toLowerCase();
            if((navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) {
                window.location = 'microsoft-edge:' + window.location;
                setTimeout(function() {
                    window.location = 'https://go.microsoft.com/fwlink/?linkid=2135547';
                }, 1);
            }else{
                gtm.init();
            }
        }

        return{
            prohibitIE:prohibitIE
        }
    })();
    const CORRECTION_VAL = 0.4;
    let isInited = false;

    let _scheduleContent = function(date){
        let that = this;
        this.showDate = new Date(date); //"11 1, 2016"
        this.today = new Date();

        let _calDate = function(){
            return that.today > that.showDate;
        }

        return{
            calDate:_calDate
        }
    };

    let _setMotion = (SB,WH,WW,target) => {
        let dis = SB - bori.getPosY(target);
        const INTENSITY = 0.2;
        let per = ( dis / (WH * 0.5) ) * (WW * INTENSITY);
        target.querySelector('.cloned').style.transform = `translate3d(${per}px,0,0)`;
    };

    let _motionTarget = (el,SB,intense=0.7) => {
        let motionTargetEl = el;
        for(let i = 0; i < motionTargetEl.length; i++){
            if( SB >= bori.getPosY(motionTargetEl[i]) + motionTargetEl[i].clientHeight * intense ){
                motionTargetEl[i].classList.add('onShow');
            }
        }
    }

    let _cloneEle = function(_target){
        const newNode = _target.cloneNode(0);
        newNode.className = 'cloned';
        _target.appendChild(newNode);
        newNode.appendChild(document.createTextNode("\u00A0"));
    };

    let _getPosY = function(element){
        let posY = element.offsetTop;
        if(element.offsetParent){
            posY += element.offsetParent.offsetTop;
        }
        return posY;
    };

    let _calRem = function(px){
        let rem = (px / 10).toFixed(3)
        return rem;
    };

    let _calVW = function(px,WW){
        let VW = (WW * ((px / 720) * 100) /100).toFixed(3)
        return VW;
    };

    let _changableVid = function( vidfileName,targetPC,targetM ){
        let vid = document.querySelector('video');
        if (matchMedia("screen and (min-width: 768px)").matches) {
            vid.setAttribute("src", `${targetPC}`);
            vid.load()
            vid.play();
        }else{
            vid.setAttribute("src", `${targetM}`);
            vid.load()
            vid.play();
        }
    };

    let _isMobile = function(W){
        return W <= 768;
    }

    let _resetMotionTarget = (el=document.querySelectorAll('.onShow')) => {
        let motionTargetEl = el;
        for(let i = 0; i <  motionTargetEl.length; i++){
            motionTargetEl[i].classList.remove('onShow');
        }
    }

    let _index = (event,target) =>{
        const clickedElement = event.target;

        // 요소 목록에서 클릭한 요소의 인덱스 찾기
        const index = Array.from(target).indexOf(clickedElement);

        // 인덱스 반환
        if (index !== -1) {
            return index;
        } else {
            return "요소를 찾을 수 없습니다.";
        }
    }

    let _chkisMac = () => {
        return navigator.platform.toUpperCase().includes('MAC');
    }

    let _trigonometricFunction = (WW, WH) => {
        // 피타고라스의 정리를 이용해 빗변의 길이를 계산
        const hypotenuse = Math.sqrt(WW * WW + WH * WH);

        // 빗변이 y축으로 얼마나 이동해야 하는지 계산 (여기서는 높이를 그대로 사용)
        const yPosition = WH; // 직각삼각형에서 높이는 빗변의 y 위치와 동일

        return{
            x:hypotenuse,
            y:yPosition
        }
    }

    function _once(fn) {
        let called = false; // 이 변수는 클로저에 의해 캡처되어 once가 반환하는 함수가 호출될 때마다 유지됩니다.
        return function () {
            if (!called) {
                called = true;
                fn.apply(this, arguments);
            }
        };
    }

    const _setHeightWithOutSoftBar = () => {

        let init = _once(function () {
            let match = window.matchMedia("(max-width: 768px)");
            let handleChange = function(e) {
                if(match.matches) {
                    if( document.querySelector(".bg-vid-box") !== null ){
                        document.querySelector(".bg-vid-box video").style.maxHeight = (window.innerHeight + window.innerHeight * 0.2) + "px"
                        document.querySelector(".bg-vid-box video").style.height = (window.innerHeight + window.innerHeight * 0.2) + "px"
                    }
                } else {
                    document.querySelector(".bg-vid-box video").style.maxHeight = '';
                    document.querySelector(".bg-vid-box video").style.height = '';
                }
            }
            window.addEventListener("change", handleChange);
            window.dispatchEvent(new Event('change'))
        });
        init();
    };

    let _copyToClipboard = () => {
        navigator.clipboard.writeText('2024flyasiana@gmail.com')
          .then(() => {
              gtag('event', 'click', {'event_category': '버튼 클릭', 'event_label': 'label', 'value': '이메일주소 클릭보드 버튼 클릭'});
              alert('응모 이메일주소가 클립보드에 저장되었습니다.')
          })
          .catch(err => {
              console.error('Could not copy text: ', err);
          });
    }

    let _gaTagHandler = (evt,content) => {
        gtag(
          'event', ''+evt,
          {'name': ''+content}
        );

        // gaTagHandler('소셜 디오디너리 버튼클릭','소셜 디오디너리 버튼클릭')"
    }

    let _ease = (() => {
        function easeInOutCubic(t) {
            return t < 0.5
              ? 4 * t * t * t
              : 1 - Math.pow(-2 * t + 2, 3) / 2;
        }

        function easeInOutQuad(t) {
            if ((t /= 0.5) < 1) return 0.5 * t * t;
            return -0.5 * (--t * (t - 2) - 1);
        }

        return{
            easeInOutCubic:easeInOutCubic,
            easeInOutQuad:easeInOutQuad
        }
    })()





    return{
        sys:_sys,
        scheduleContent:_scheduleContent,
        setMotion:_setMotion,
        cloneEle:_cloneEle,
        getPosY:_getPosY,
        calRem:_calRem,
        calVW:_calVW,
        motionTarget:_motionTarget,
        resetMotionTarget:_resetMotionTarget,
        changableVid:_changableVid,
        isMobile:_isMobile,
        index:_index,
        chkisMac:_chkisMac,
        trigonometricFunction:_trigonometricFunction,
        setHeightWithOutSoftBar:_setHeightWithOutSoftBar,
        once:_once,
        copyToClipboard:_copyToClipboard,
        gaTagHandler:_gaTagHandler,
        ease:_ease
    }
})()

export { bori }