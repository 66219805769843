<script setup>
	import {useMainStore} from "@/stores/mainStore";
	import {onBeforeUnmount, onMounted, onUnmounted, watch, ref, nextTick} from "vue";
	const mainStore = useMainStore();
	// import menData from '@/assets/json/datas_men.json';
	// import womenData from '@/assets/json/datas_women.json';
	let fetchedDatas = ref([]);
	let isGenderChanged = false;
	let limitIdx = ref(0);
	let selectedGender;
	let realIdx = ref(0);


	onMounted(() => {
		const DELAY = 100;
		if( mainStore.isMobile ) limitIdx.value = 0;
		else limitIdx.value =3;
		watch(()=> mainStore.isMobile,(newValue)=>{
			if( newValue ) limitIdx.value = 0;
			else limitIdx.value = 3;
		})
		watch(() => mainStore.selectedGender, (newValue) => {
			let gender;
			if( newValue === 'men') gender = 'M';
			else if ( newValue === 'women') gender = 'F';



			isGenderChanged = true;
			setTimeout(()=>{
				document.querySelectorAll('.ranking > li').forEach((item, index) => {
					item.classList.remove('onShow');
				});

        if( isGenderChanged ){
					fetch('https://runjeju-record.com/data/rank', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							gender: gender,
						})
					})
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok ' + response.statusText);
							}
							return response.json();
						})
						.then(data => {
							fetchedDatas.value = data;
							// for( let i=0;i<fetchedDatas.length;i++){
							// 	console.log(fetchedDatas)
							// }
						})
						.catch(error => {
							console.error('There has been a problem with your fetch operation:', error);
						});
          setTimeout(()=>{
            document.querySelectorAll('.ranking > li').forEach((item, index) => {
              setTimeout(()=>{
                item.classList.add('onShow');
              }, 10 * (index + 1)); // 각 요소마다 0.1초 지연
            });
            isGenderChanged = false;
          },300);
        }
			},DELAY);



    });
		watch(() => mainStore.isInited, (newValue) => {

			// 더미데이터 테스트
			/*fetchedDatas.value = menData;
			setTimeout(()=>{
				document.querySelectorAll('.ranking li').forEach((item, index) => {
					setTimeout(() => {
						item.classList.add('onShow');
					}, (75) * (index + 1)); // 각 요소마다 0.1초 지연
				})
			},DELAY);*/


			// 실제 테스트
			fetch('https://runjeju-record.com/data/rank', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					gender: 'M',
				})
			})
				.then(response => {
					if (!response.ok) {
						throw new Error('Network response was not ok ' + response.statusText);
					}
					return response.json();
				})
				.then(data => {
					fetchedDatas.value = data;
					for( let i=0;i<fetchedDatas.value.length;i++){
						if( fetchedDatas.value[i].num !== '-' ){
							realIdx.value = realIdx.value + 1;
						}
					}

					if( !mainStore.isMobile ){
						realIdx.value = fetchedDatas.value.length;
					}



					setTimeout(()=>{
						document.querySelectorAll('.ranking li').forEach((item, index) => {
							setTimeout(() => {
								item.classList.add('onShow');
							}, (75) * (index + 1)); // 각 요소마다 0.1초 지연
						})
					},DELAY);
				})
				.catch(error => {
					console.error('There has been a problem with your fetch operation:', error);
				});


		});

		let resizeHandler = () => {
			if( mainStore.isMobile ) limitIdx.value = 0;
			else limitIdx.value = 3;
		}
		window.addEventListener("resize", resizeHandler);
	});
	onUnmounted(() => {
		window.removeEventListener("resize", resizeHandler);
	})
</script>
<template>
	<div>
		<div class="rank-fixed">
			<div class="mask">
				<div>순위</div>
				<div>배번</div>
				<div>팀명</div>
				<div>성별</div>
				<div>기록</div>
			</div>
		</div>
		<ul class="ranking">
			<li v-for="(data,index) in fetchedDatas" :key="data.ranks">
				<div class="mask">
					<div>{{ data.rank }}</div>
					<div>{{ data.num }}</div>
					<div>{{ data.name }}</div>
					<div>
						<em v-if="data.gender==='M'">남</em>
						<em v-else-if="data.gender==='F'">여</em>
						<em v-else>-</em>
					</div>
          <div>
            <span v-if="data.is_disqualified === 'Y' || data.is_participate === '미참가'">
              00:00.00
            </span>
            <span v-else>
              {{data.record_nettime}}
            </span>
          </div>
				</div>
			</li>
		</ul>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/assets/scss/ranking/primary_ranking';
</style>