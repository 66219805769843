import { defineStore } from "pinia"

export const useMainStore = defineStore('parallax_store', () => {
  let _isShowPopup = false;
  let _isMobile = false;


  return {
    isMobile:_isMobile,
    isShowPopup:_isShowPopup
  }
})